<template>
	<div>
		<a-modal title="添加会员" :width="400" :visible="visible" @cancel="handleCancel">
			<template slot="footer" >
				<div class="flex alcenter center">
					<div class="cancel-button"  key="back" @click="handleCancel">
						取消
					</div>
					
					<a-button class="sure-button ml20" key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
						保存
					</a-button>
				</div>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="add-member-form flex wrap mt10">
						<a-input placeholder="请输入会员名" v-model="form.nick_name" :maxLength="20">
							<div slot="prefix" class="input-addon-before">
								会员名
							</div>
						</a-input>

						<a-input class="mt20" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
							<div slot="prefix" class="input-addon-before">
								手机号
							</div>
						</a-input>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Number,
				default: 0
			},
			visible: {
				type: Boolean,
				default: false,
			},
		},

		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					nick_name: '',
					mobile: '',
				}
			}
		},
		methods: {
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancelAddMember");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('saveMember', {
					nick_name: this.form.nick_name,
					mobile: this.form.mobile,
				}).then(res => {
					this.$message.success('添加成功', 1, () => {
						this.confirmLoading = false;
						let value = res.member_id;
						this.$emit('input', value);
						this.$emit("okAddMember");
					})

				}).catch(res => {
					console.log(res);
					this.confirmLoading = false;
				})
			}
		}
	}
</script>

<style>
	.add-member-form .ant-input {
		height: 48px;
		background: #FFFFFF;
		border-radius: 24px;
		border: 1px solid #DCDFE6;
		font-size: 16px;
		font-weight: 400;
		color: #C5CADB;
	}

	.add-member-form .ant-input-affix-wrapper .ant-input:not(:first-child) {
		padding-left: 88px;
	}

	.add-member-form .input-addon-before {
		width: 64px;
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		color: #5C5E66;
	}
</style>
