<template>
	<div class="index-bg" style="height: calc(100vh - 60px);">
		<div class="flex center alcenter index-bg-main">
			<div class="index-main">
				<div style="position: relative;">
					<a-input placeholder="输入手机号或扫描会员码" v-model="search.mobile"/>
					<div class="index-main-input-cover" @click="onSearch()">
						<i class="iconfont iconsearch_gray"/>
					</div>
				</div>
				
				<div  class="mt20">
					<div class=" ft14 ftw600 text-center" style="color: #FF8800;" v-if="msgVisible">
						{{msg}}
					</div>
				</div>
				
				
				<div class="index-main-add-vip" @click="addMemberAct()">
					<img src="../../assets/image/cashier_btn_kaika@2x.png"/>
				</div>
			</div>
		</div>
		
		<div class="index-footer">
			<img src="../../assets/image/cashier_bg@2x.png"/>
		</div>
		
		<div v-if="addMemberVisible">
			<edit-member v-model="member_id" :visible="addMemberVisible" @cancelAddMember="cancelAddMember" @okAddMember="okAddMember"></edit-member>
		</div>
	</div>
</template>

<script>
	import editMember from './components/index/modal/editMember.vue';
	export default{
		components: {
			editMember,
		},
		data(){
			return{
				loading: false,
				msgVisible:false,
				member_id:0,
				msg:"",
				search: {
					mobile: "",
				},
				addMemberVisible: false,
			}
		},
		mounted() {
			//绑定事件
			window.addEventListener('keydown', this.keyDown);
		},
		methods:{
			
			onSearch() {
				if (this.searchLoading == true) return;
				this.searchLoading = true;
				this.msgVisible=false;
				this.$http.api('getMemberId', {
					mobile: this.search.mobile,
				}).then(res => {
					this.searchLoading = false;
					this.$message.success('查询成功，即将跳转',1,()=>{
						this.$router.push('/member/detail?id='+res.member_id)
					})
				}).catch(res => {
					console.log(res);
					this.msg=res.msg;
					this.msgVisible=true;
					this.searchLoading = false;
				})
			},
			
			
			addMemberAct() {
				this.member_id=0;
				this.addMemberVisible = true;
			},
					
			cancelAddMember() {
				this.addMemberVisible = false;
			},
			okAddMember() {
				this.addMemberVisible = false;
				this.$router.push('/member/detail?id='+this.member_id)
			},
			keyDown(e) {
				//如果是回车则执行登录方法
				if (e.keyCode == 13) {
					this.onSearch();
				}
			},
		},
		destroyed() {
			window.removeEventListener('keydown', this.keyDown, false);
		}
	}
</script>

<style>
	.index-bg{
		background: #ffffff;
		height: calc(100vh - 60px);
	}
	.index-bg-main{
		height: 100%;
	}
	.index-main .ant-input{
		width: 480px;
		height: 80px;
		background: #4772FF;
		border-radius: 40px;
		border: 1px solid #E6E6E6;
		font-size: 20px;
		font-family: PingFang-SC-Bold, PingFang-SC;
		font-weight: bold;
		color: #FFFFFF;
		padding-left: 30px;
	}
	
	.index-main-input-cover{
		width: 100px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 40px;
		position: absolute;
		top: 10px;
		right: 10px;
		text-align: center;
		cursor: pointer;
	}
	
	.index-main-input-cover i{
		font-size: 30px;
		line-height: 60px;
		color: #4772FF;
	}
	
	.index-footer{
		height: 170px;
		position: fixed;
		bottom: 0px;
	}
	
	.index-footer img{
		height: 170px;
	}
	
	.index-main-add-vip{
		margin-top: 80px;
		width: 480px;
		height: 120px;
		background: linear-gradient(225deg, #FDC753 0%, #FB983E 100%);
		border-radius: 120px;
	}
	
	.index-main-add-vip img{
		width: 480px;
		height: 120px;
		cursor: pointer;
	}
	@media screen and (max-width: 1024px) {
		
	}
	
	@media screen and (min-width:1025px) and (max-width: 1920px) {
		
	}
</style>
